<template>

	<Teleport to="#notifications">
		<div :id="'notification-'+id" class="notification-container" :class="{active, 'error':type===-2, 'warning':type===-1, 'success':type===1}" :style="'--notification-color:'+progressColor">
			<div class="notification-wrapper">
				<div class="notification-inner">
					<div class="close-btn" @click="closeNotification" v-html="getIcon('iconX')"></div>
					<div v-if="title" class="notification-header">
						<h3>{{ title }}</h3>
					</div>
					<div class="notification-body">
						<p>{{ currentBodyText }}</p>
					</div>
					<AppProgressBar :color="progressColor" v-if="duration" :progress="currentProgress" manual />
				</div>
			</div>
		</div>
	</Teleport>

</template>

<script>
import AppProgressBar from "@/shared/components/AppProgressBar.vue";
import {getRandomAlphanumericString} from "@/shared/helpers/strings";
import {useSvgStore} from "@/shared/stores";

export default {
	setup() {
		let svgStore = useSvgStore();
		return {getIcon: svgStore.get}
	},
	name: "AppNotification",
	emits: ["toggle-notification"],
	components: {AppProgressBar},
	data() {
		return {
			active: false,
			progressInterval: null,
			startTime: null,
			currentProgress: 0,
			timeoutClose: null,
			currentBodyText: this.bodyText,
		}
	},
	props: {
		title: {type: String, required: true},
		bodyText: {type: String, required: true},
		duration: {type: Number, default: 4300},
		id: {type: String, default: () => getRandomAlphanumericString()},
		type: {type: Number, default: 0, validator: (value) => value >= -2 && value <= 1},
		forceColor: {type: String, default: null},
	},
	methods: {
		openNotification() {
			if(this.active) {
				this.startTime = Date.now();
				this.currentProgress = 0;
				if(this.timeoutClose) {
					clearTimeout(this.timeoutClose);
					this.timeoutClose = setTimeout(() => {
						this.timeoutClose = null;
						this.closeNotification();
					}, this.duration);
				}
				return;
			}
			this.active = true;
			document.getElementById('notification-'+this.id).style.order = (getHighestNotificationOrder()+1)+"";
			window.notifications.push(this);
			if(this.duration) {
				this.startTime = Date.now();
				this.progressInterval = setInterval(() => {
					let p = ((Date.now() - this.startTime) / this.duration)*100;
					if(p >= 100) {
						this.currentProgress = 100;
					} else if(p <= 0) {
						this.currentProgress = 0;
					} else {
						this.currentProgress = p;
					}
				}, 30);
				this.timeoutClose = setTimeout(() => {
					this.timeoutClose = null;
					this.closeNotification();
				}, this.duration);
			}
			this.$emit("toggle-notification", true);
		},
		closeNotification() {
			if(this.timeoutClose) {
				clearTimeout(this.timeoutClose);
				this.timeoutClose = null;
			}
			if(!this.active) {
				return;
			}
			this.active = false;
			if(this.duration) {
				clearInterval(this.progressInterval);
			}
			setTimeout(() => {
				window.notifications.splice(window.notifications.indexOf(this), 1);
				if(document.getElementById('notification-'+this.id)) {
					document.getElementById('notification-'+this.id).style.order = "99999";
				}
				this.$emit("toggle-notification", false);
			}, 550);
		},
	},
	created() {
		if(!document.getElementById("notifications")) {
			let popups = document.createElement("div");
			popups.id = "notifications";
			document.body.appendChild(popups);
		}
	},
	computed: {
		progressColor() {
			if(this.forceColor) {
				return this.forceColor;
			}
			if(this.type === -2) {
				return 'var(--color-danger)';
			} else if(this.type === -1) {
				return 'var(--color-warn)';
			} else if(this.type === 1) {
				return 'var(--color-secondary)';
			}
			return '#7E9BC7';
		},
	},
	watch: {
		bodyText() {
			this.currentBodyText = this.bodyText;
		}
	}
}

function getHighestNotificationOrder() {
	let currMax = Math.max.apply(Math, window.notifications.map(function(o) {
		if(document.getElementById('notification-'+o.id) && document.getElementById('notification-'+o.id).style && document.getElementById('notification-'+o.id).style.order) {
			return Number(document.getElementById('notification-'+o.id).style.order);
		}
		return -1;
	}));
	if(currMax >= 99999 || currMax < 0) {
		return -1;
	}
	return currMax;
}

</script>

<style lang="scss">
#notifications {
	position: fixed;
	top: 0;
	right: -350px;
	width: 690px;
	pointer-events: none;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	* {
		pointer-events: none;
	}
}
</style>

<style lang="scss" scoped>
.notification-container {
	transition: margin-right 0.5s $transition, max-height 0.5s $transition, padding-top 0.5s $transition;
	margin-left: auto;
	margin-right: 0;
	height: auto;
	max-height: 0;
	padding-top: 0;
	order: 99999;

	&.active {
		max-height: 600px;
		margin-right: 370px;
		padding-top: 25px;
	}

	.notification-wrapper {
		background-color: $bg_0;
		box-shadow: 0 5px 15px $alpha_3;
		border-radius: 13px;
		width: 300px;
	}

	.notification-inner {
		position: relative;
		padding: 15px 20px;
	}

	.close-btn {
		width: 12px;
		height: 12px;
		position: absolute;
		top: 16px;
		right: 12px;
		cursor: pointer;
		pointer-events: all !important;
		display: flex;
		justify-content: center;
		align-items: center;

		&:deep(svg) {
			width: 100%;
			height: 100%;

			.fill {
				fill: $txt_1;
			}

			.stroke {
				stroke: $txt_1;
			}
		}
	}

	.notification-header {
		position: relative;
		padding-bottom: 10px;

		h3 {
			@include wordWrap;
			color: $primary;
			font-size: 12px;
			font-style: italic;
		}

		& ~ .notification-body {
			padding-top: 10px;
		}
	}

	.notification-body {
		position: relative;
		padding-bottom: 10px;

		p {
			@include wordWrap;
			color: $txt_1;
			line-height: 16px;
			font-size: 14px;
			font-style: italic;
		}
	}

	.notification-header{
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $alpha;
		}
		h3 {
			color: var(--notification-color);
		}
	}

}

</style>