<template>

	<div v-if="!indeterminate" :style="'--progress: '+currentProgress+'%;--progress-color:'+color" class="progress-container" :class="{manual: manual}"></div>
	<div v-if="indeterminate" :style="'--progress-color:'+color" class="progress-container indeterminate"></div>

</template>

<script>

export default {
	name: "AppProgressBar",
	props: {
		progress: {type: Number, default: 0},
		max: {type: Number, default: 100},
		manual: {type: Boolean, default: false},
		color: {type: String, default: '#7E9BC7'},
		indeterminate: {type: Boolean, default: false}
	},
	computed: {
		currentProgress() {
			return (this.progress / this.max) * 100;
		}
	},
}
</script>

<style lang="scss" scoped>

:root {
	--progress: 0%;
	--progress-color: #7E9BC7;
}

.progress-container {
	position: relative;
	height: 7px;
	width: 100%;
	box-shadow: inset 0 1px 6px $alpha_3;
	border-radius: 3px;
	overflow: hidden;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: var(--progress);
		height: 100%;
		background-color: var(--progress-color);
		max-width: 100%;
		transition: width 0.3s ease-in-out;
	}

	&.manual {
		&:after {
			transition: none;
		}
	}

	&.indeterminate {
		&:after {
			width: 30%;
			animation: loading 2s $transition infinite;
		}
	}
}

@keyframes loading {
	from {
		left: -30%;
	}
	to {
		left: 130%;
	}
}

</style>